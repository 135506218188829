export const countries = [
    {
        name: "Afghanistan",
        citizenship: "423",
        iso: "AF",
        ldkz: "AFG",
    },
    {
        name: "Ägypten",
        citizenship: "287",
        iso: "EG",
        ldkz: "ET",
    },
    {
        name: "Åland",
        citizenship: "128",
        iso: "AX",
        ldkz: "AX",
    },
    {
        name: "Albanien",
        citizenship: "121",
        iso: "AL",
        ldkz: "AL",
    },
    {
        name: "Algerien",
        citizenship: "221",
        iso: "DZ",
        ldkz: "DZ",
    },
    {
        name: "Amerikanische Jungferninseln",
        citizenship: "368",
        iso: "VI",
        ldkz: "AJ",
    },
    {
        name: "Amerikanisch-Samoa",
        citizenship: "368",
        iso: "AS",
        ldkz: "AS",
    },
    {
        name: "Andorra",
        citizenship: "123",
        iso: "AD",
        ldkz: "AND",
    },
    {
        name: "Angola",
        citizenship: "223",
        iso: "AO",
        ldkz: "AGO",
    },
    {
        name: "Anguilla",
        citizenship: "185",
        iso: "AI",
        ldkz: "ANG",
    },
    {
        name: "Antigua und Barbuda",
        citizenship: "320",
        iso: "AG",
        ldkz: "ANT",
    },
    {
        name: "Äquatorialguinea",
        citizenship: "274",
        iso: "GQ",
        ldkz: "AQU",
    },
    {
        name: "Argentinien",
        citizenship: "323",
        iso: "AR",
        ldkz: "RA",
    },
    {
        name: "Argentinische Antarktis",
        citizenship: "323",
        iso: "AQ",
        ldkz: "AQ",
    },
    {
        name: "Armenien",
        citizenship: "422",
        iso: "AM",
        ldkz: "ARM",
    },
    {
        name: "Aruba",
        citizenship: "148",
        iso: "AW",
        ldkz: "AW",
    },
    {
        name: "Ascension",
        citizenship: "185",
        iso: "SH",
        ldkz: "HEL",
    },
    {
        name: "Aserbaidschan",
        citizenship: "425",
        iso: "AZ",
        ldkz: "ASE",
    },
    {
        name: "Äthiopien",
        citizenship: "225",
        iso: "ET",
        ldkz: "ETH",
    },
    {
        name: "Australien",
        citizenship: "523",
        iso: "AU",
        ldkz: "AUS",
    },
    {
        name: "Australisches Antarktis-Territorium",
        citizenship: "523",
        iso: "AQ",
        ldkz: "AQ",
    },
    {
        name: "Bahamas",
        citizenship: "324",
        iso: "BS",
        ldkz: "BS",
    },
    {
        name: "Bahrain",
        citizenship: "424",
        iso: "BH",
        ldkz: "BRN",
    },
    {
        name: "Bangladesch",
        citizenship: "460",
        iso: "BD",
        ldkz: "BD",
    },
    {
        name: "Barbados",
        citizenship: "322",
        iso: "BB",
        ldkz: "BDS",
    },
    {
        name: "Belarus",
        citizenship: "169",
        iso: "BY",
        ldkz: "BY",
    },
    {
        name: "Belgien",
        citizenship: "124",
        iso: "BE",
        ldkz: "B",
    },
    {
        name: "Belize",
        citizenship: "330",
        iso: "BZ",
        ldkz: "BH",
    },
    {
        name: "Benin",
        citizenship: "229",
        iso: "BJ",
        ldkz: "DY",
    },
    {
        name: "Bermuda",
        citizenship: "185",
        iso: "BM",
        ldkz: "BER",
    },
    {
        name: "Bhutan",
        citizenship: "426",
        iso: "BT",
        ldkz: "BHT",
    },
    {
        name: "Bolivien",
        citizenship: "326",
        iso: "BO",
        ldkz: "BOL",
    },
    {
        name: "Bonaire, Saba, St. Eustatius",
        citizenship: "148",
        iso: "BQ",
        ldkz: "BQ",
    },
    {
        name: "Bosnien und Herzegowina",
        citizenship: "122",
        iso: "BA",
        ldkz: "BIH",
    },
    {
        name: "Botsuana",
        citizenship: "227",
        iso: "BW",
        ldkz: "RB",
    },
    {
        name: "Bouvetinsel",
        citizenship: "149",
        iso: "BV",
        ldkz: "BV",
    },
    {
        name: "Brasilien",
        citizenship: "327",
        iso: "BR",
        ldkz: "BR",
    },
    {
        name: "Britische Jungferninseln",
        citizenship: "185",
        iso: "VG",
        ldkz: "BJ",
    },
    {
        name: "Britisches Antarktis-Territorium",
        citizenship: "185",
        iso: "AQ",
        ldkz: "AQ",
    },
    {
        name: "Britisches Territorium im Indischen Ozean",
        citizenship: "185",
        iso: "IO",
        ldkz: "IO",
    },
    {
        name: "Brunei Darussalam",
        citizenship: "429",
        iso: "BN",
        ldkz: "BRU",
    },
    {
        name: "Bulgarien",
        citizenship: "125",
        iso: "BG",
        ldkz: "BG",
    },
    {
        name: "Burkina Faso",
        citizenship: "258",
        iso: "BF",
        ldkz: "HV",
    },
    {
        name: "Burundi",
        citizenship: "291",
        iso: "BI",
        ldkz: "RU",
    },
    {
        name: "Cabo Verde",
        citizenship: "242",
        iso: "CV",
        ldkz: "CV",
    },
    {
        name: "Chile",
        citizenship: "332",
        iso: "CL",
        ldkz: "RCH",
    },
    {
        name: "Chilenische Antarktis",
        citizenship: "332",
        iso: "AQ",
        ldkz: "AQ",
    },
    {
        name: "China",
        citizenship: "479",
        iso: "CN",
        ldkz: "TJ",
    },
    {
        name: "Clipperton",
        citizenship: "129",
        iso: "CP",
        ldkz: "CP",
    },
    {
        name: "Cookinseln",
        citizenship: "536",
        iso: "CK",
        ldkz: "COI",
    },
    {
        name: "Costa Rica",
        citizenship: "334",
        iso: "CR",
        ldkz: "CR",
    },
    {
        name: "Côte d’Ivoire",
        citizenship: "231",
        iso: "CI",
        ldkz: "CI",
    },
    {
        name: "Curaçao",
        citizenship: "148",
        iso: "CW",
        ldkz: "CW",
    },
    {
        name: "Dänemark",
        citizenship: "126",
        iso: "DK",
        ldkz: "DK",
    },
    {
        name: "Deutschland",
        citizenship: "000",
        iso: "DE",
        ldkz: "D",
    },
    {
        name: "Dominica",
        citizenship: "333",
        iso: "DM",
        ldkz: "WD",
    },
    {
        name: "Dominikanische Republik",
        citizenship: "335",
        iso: "DO",
        ldkz: "DOM",
    },
    {
        name: "Dschibuti",
        citizenship: "230",
        iso: "DJ",
        ldkz: "DSC",
    },
    {
        name: "Ecuador",
        citizenship: "336",
        iso: "EC",
        ldkz: "EC",
    },
    {
        name: "El Salvador",
        citizenship: "337",
        iso: "SV",
        ldkz: "ES",
    },
    {
        name: "Eritrea",
        citizenship: "224",
        iso: "ER",
        ldkz: "ERI",
    },
    {
        name: "Estland",
        citizenship: "127",
        iso: "EE",
        ldkz: "EST",
    },
    {
        name: "Eswatini",
        citizenship: "281",
        iso: "SZ",
        ldkz: "SWZ",
    },
    {
        name: "Falklandinseln",
        citizenship: "185",
        iso: "FK",
        ldkz: "FAL",
    },
    {
        name: "Färöer",
        citizenship: "126",
        iso: "FO",
        ldkz: "FR",
    },
    {
        name: "Fidschi",
        citizenship: "526",
        iso: "FJ",
        ldkz: "FJI",
    },
    {
        name: "Finnland",
        citizenship: "128",
        iso: "FI",
        ldkz: "FIN",
    },
    {
        name: "Frankreich",
        citizenship: "129",
        iso: "FR",
        ldkz: "F",
    },
    {
        name: "Französische Süd- und Antarktisgebiete",
        citizenship: "129",
        iso: "TF",
        ldkz: "TF",
    },
    {
        name: "Französisch-Guayana",
        citizenship: "129",
        iso: "GF",
        ldkz: "FG",
    },
    {
        name: "Französisch-Polynesien",
        citizenship: "129",
        iso: "PF",
        ldkz: "FP",
    },
    {
        name: "Gabun",
        citizenship: "236",
        iso: "GA",
        ldkz: "GAB",
    },
    {
        name: "Gambia",
        citizenship: "237",
        iso: "GM",
        ldkz: "WAG",
    },
    {
        name: "Georgien",
        citizenship: "430",
        iso: "GE",
        ldkz: "GEO",
    },
    {
        name: "Ghana",
        citizenship: "238",
        iso: "GH",
        ldkz: "GH",
    },
    {
        name: "Gibraltar",
        citizenship: "168",
        iso: "GI",
        ldkz: "GIB",
    },
    {
        name: "Grenada",
        citizenship: "340",
        iso: "GD",
        ldkz: "WG",
    },
    {
        name: "Griechenland",
        citizenship: "134",
        iso: "GR",
        ldkz: "GR",
    },
    {
        name: "Grönland",
        citizenship: "126",
        iso: "GL",
        ldkz: "GRO",
    },
    {
        name: "Guadeloupe",
        citizenship: "129",
        iso: "GP",
        ldkz: "GUA",
    },
    {
        name: "Guam",
        citizenship: "368",
        iso: "GU",
        ldkz: "GUM",
    },
    {
        name: "Guatemala",
        citizenship: "345",
        iso: "GT",
        ldkz: "GCA",
    },
    {
        name: "Guernsey",
        citizenship: "168",
        iso: "GG",
        ldkz: "GG",
    },
    {
        name: "Guinea",
        citizenship: "261",
        iso: "GN",
        ldkz: "RG",
    },
    {
        name: "Guinea-Bissau",
        citizenship: "259",
        iso: "GW",
        ldkz: "GUB",
    },
    {
        name: "Guyana",
        citizenship: "328",
        iso: "GY",
        ldkz: "GUY",
    },
    {
        name: "Haiti",
        citizenship: "346",
        iso: "HT",
        ldkz: "RH",
    },
    {
        name: "Heard und McDonaldinseln",
        citizenship: "523",
        iso: "HM",
        ldkz: "HM",
    },
    {
        name: "Honduras",
        citizenship: "347",
        iso: "HN",
        ldkz: "HCA",
    },
    {
        name: "Hongkong",
        citizenship: "411",
        iso: "HK",
        ldkz: "HKG",
    },
    {
        name: "Indien",
        citizenship: "436",
        iso: "IN",
        ldkz: "IND",
    },
    {
        name: "Indonesien",
        citizenship: "437",
        iso: "ID",
        ldkz: "RI",
    },
    {
        name: "Insel Man",
        citizenship: "168",
        iso: "IM",
        ldkz: "MAN",
    },
    {
        name: "Irak",
        citizenship: "438",
        iso: "IQ",
        ldkz: "IRQ",
    },
    {
        name: "Iran",
        citizenship: "439",
        iso: "IR",
        ldkz: "IR",
    },
    {
        name: "Irland",
        citizenship: "135",
        iso: "IE",
        ldkz: "IRL",
    },
    {
        name: "Island",
        citizenship: "136",
        iso: "IS",
        ldkz: "IS",
    },
    {
        name: "Israel",
        citizenship: "441",
        iso: "IL",
        ldkz: "IL",
    },
    {
        name: "Italien",
        citizenship: "137",
        iso: "IT",
        ldkz: "I",
    },
    {
        name: "Jamaika",
        citizenship: "355",
        iso: "JM",
        ldkz: "JA",
    },
    {
        name: "Japan",
        citizenship: "442",
        iso: "JP",
        ldkz: "J",
    },
    {
        name: "Jemen",
        citizenship: "421",
        iso: "YE",
        ldkz: "YEM",
    },
    {
        name: "Jersey",
        citizenship: "168",
        iso: "JE",
        ldkz: "JE",
    },
    {
        name: "Jordanien",
        citizenship: "445",
        iso: "JO",
        ldkz: "JOR",
    },
    {
        name: "Kaimaninseln",
        citizenship: "185",
        iso: "KY",
        ldkz: "KAI",
    },
    {
        name: "Kambodscha",
        citizenship: "446",
        iso: "KH",
        ldkz: "K",
    },
    {
        name: "Kamerun",
        citizenship: "262",
        iso: "CM",
        ldkz: "CAM",
    },
    {
        name: "Kanada",
        citizenship: "348",
        iso: "CA",
        ldkz: "CDN",
    },
    {
        name: "Kasachstan",
        citizenship: "444",
        iso: "KZ",
        ldkz: "KAS",
    },
    {
        name: "Katar",
        citizenship: "447",
        iso: "QA",
        ldkz: "QAT",
    },
    {
        name: "Kenia",
        citizenship: "243",
        iso: "KE",
        ldkz: "EAK",
    },
    {
        name: "Kirgisistan",
        citizenship: "450",
        iso: "KG",
        ldkz: "KIS",
    },
    {
        name: "Kiribati",
        citizenship: "530",
        iso: "KI",
        ldkz: "KIB",
    },
    {
        name: "Kleinere Amerikanische Überseeinseln",
        citizenship: "368",
        iso: "UM",
        ldkz: "UM",
    },
    {
        name: "Kokosinseln",
        citizenship: "523",
        iso: "CC",
        ldkz: "CC",
    },
    {
        name: "Kolumbien",
        citizenship: "349",
        iso: "CO",
        ldkz: "CO",
    },
    {
        name: "Komoren",
        citizenship: "244",
        iso: "KM",
        ldkz: "KOM",
    },
    {
        name: "Kongo",
        citizenship: "245",
        iso: "CG",
        ldkz: "RCB",
    },
    {
        name: "Kongo, Demokratische Republik",
        citizenship: "246",
        iso: "CD",
        ldkz: "ZRE",
    },
    {
        name: "Korallenmeerinseln",
        citizenship: "523",
        iso: "AU",
        ldkz: "AU",
    },
    {
        name: "Korea, Demokratische Volksrepublik",
        citizenship: "434",
        iso: "KP",
        ldkz: "KOR",
    },
    {
        name: "Korea, Republik",
        citizenship: "467",
        iso: "KR",
        ldkz: "ROK",
    },
    {
        name: "Kosovo",
        citizenship: "150",
        iso: "XK",
        ldkz: "KOS",
    },
    {
        name: "Kroatien",
        citizenship: "130",
        iso: "HR",
        ldkz: "HR",
    },
    {
        name: "Kuba",
        citizenship: "351",
        iso: "CU",
        ldkz: "C",
    },
    {
        name: "Kuwait",
        citizenship: "448",
        iso: "KW",
        ldkz: "KWT",
    },
    {
        name: "Laos",
        citizenship: "449",
        iso: "LA",
        ldkz: "LAO",
    },
    {
        name: "Lesotho",
        citizenship: "226",
        iso: "LS",
        ldkz: "LS",
    },
    {
        name: "Lettland",
        citizenship: "139",
        iso: "LV",
        ldkz: "LV",
    },
    {
        name: "Libanon",
        citizenship: "451",
        iso: "LB",
        ldkz: "RL",
    },
    {
        name: "Liberia",
        citizenship: "247",
        iso: "LR",
        ldkz: "LB",
    },
    {
        name: "Libyen",
        citizenship: "248",
        iso: "LY",
        ldkz: "LAR",
    },
    {
        name: "Liechtenstein",
        citizenship: "141",
        iso: "LI",
        ldkz: "FL",
    },
    {
        name: "Litauen",
        citizenship: "142",
        iso: "LT",
        ldkz: "LT",
    },
    {
        name: "Luxemburg",
        citizenship: "143",
        iso: "LU",
        ldkz: "L",
    },
    {
        name: "Macau",
        citizenship: "412",
        iso: "MO",
        ldkz: "MAC",
    },
    {
        name: "Madagaskar",
        citizenship: "249",
        iso: "MG",
        ldkz: "RM",
    },
    {
        name: "Malawi",
        citizenship: "256",
        iso: "MW",
        ldkz: "MW",
    },
    {
        name: "Malaysia",
        citizenship: "482",
        iso: "MY",
        ldkz: "MAL",
    },
    {
        name: "Malediven",
        citizenship: "454",
        iso: "MV",
        ldkz: "BIO",
    },
    {
        name: "Mali",
        citizenship: "251",
        iso: "ML",
        ldkz: "RMM",
    },
    {
        name: "Malta",
        citizenship: "145",
        iso: "MT",
        ldkz: "M",
    },
    {
        name: "Marokko",
        citizenship: "252",
        iso: "MA",
        ldkz: "MA",
    },
    {
        name: "Marshallinseln",
        citizenship: "544",
        iso: "MH",
        ldkz: "MAR",
    },
    {
        name: "Martinique",
        citizenship: "129",
        iso: "MQ",
        ldkz: "MAT",
    },
    {
        name: "Mauretanien",
        citizenship: "239",
        iso: "MR",
        ldkz: "RIM",
    },
    {
        name: "Mauritius",
        citizenship: "253",
        iso: "MU",
        ldkz: "MS",
    },
    {
        name: "Mayotte",
        citizenship: "129",
        iso: "YT",
        ldkz: "MAY",
    },
    {
        name: "Mazedonien",
        citizenship: "157",
        iso: "MK",
        ldkz: "MK",
    },
    {
        name: "Mexiko",
        citizenship: "353",
        iso: "MX",
        ldkz: "MEX",
    },
    {
        name: "Mikronesien",
        citizenship: "545",
        iso: "FM",
        ldkz: "MIK",
    },
    {
        name: "Moldau",
        citizenship: "146",
        iso: "MD",
        ldkz: "MD",
    },
    {
        name: "Monaco",
        citizenship: "147",
        iso: "MC",
        ldkz: "MC",
    },
    {
        name: "Mongolei",
        citizenship: "457",
        iso: "MN",
        ldkz: "MON",
    },
    {
        name: "Montenegro",
        citizenship: "140",
        iso: "ME",
        ldkz: "MNE",
    },
    {
        name: "Montserrat",
        citizenship: "185",
        iso: "MS",
        ldkz: "MOT",
    },
    {
        name: "Mosambik",
        citizenship: "254",
        iso: "MZ",
        ldkz: "MOZ",
    },
    {
        name: "Myanmar",
        citizenship: "427",
        iso: "MM",
        ldkz: "MYA",
    },
    {
        name: "Namibia",
        citizenship: "267",
        iso: "NA",
        ldkz: "SWA",
    },
    {
        name: "Nauru",
        citizenship: "531",
        iso: "NR",
        ldkz: "NAU",
    },
    {
        name: "Navassa",
        citizenship: "368",
        iso: "UM",
        ldkz: "UM",
    },
    {
        name: "Nepal",
        citizenship: "458",
        iso: "NP",
        ldkz: "NEP",
    },
    {
        name: "Neukaledonien",
        citizenship: "129",
        iso: "NC",
        ldkz: "NKA",
    },
    {
        name: "Neuseeland",
        citizenship: "536",
        iso: "NZ",
        ldkz: "NZ",
    },
    {
        name: "Neuseeländische Antarktis",
        citizenship: "536",
        iso: "AQ",
        ldkz: "AQ",
    },
    {
        name: "Nicaragua",
        citizenship: "354",
        iso: "NI",
        ldkz: "NIC",
    },
    {
        name: "Niederlande",
        citizenship: "148",
        iso: "NL",
        ldkz: "NL",
    },
    {
        name: "Niger",
        citizenship: "255",
        iso: "NE",
        ldkz: "RN",
    },
    {
        name: "Nigeria",
        citizenship: "232",
        iso: "NG",
        ldkz: "WAN",
    },
    {
        name: "Niue",
        citizenship: "536",
        iso: "NU",
        ldkz: "NIU",
    },
    {
        name: "Nördliche Marianen",
        citizenship: "368",
        iso: "MP",
        ldkz: "NMA",
    },
    {
        name: "Norfolkinsel",
        citizenship: "523",
        iso: "NF",
        ldkz: "NF",
    },
    {
        name: "Nordmazedonien",
        citizenship: "144",
        iso: "MK",
        ldkz: "MK",
    },
    {
        name: "Norwegen",
        citizenship: "149",
        iso: "NO",
        ldkz: "N",
    },
    {
        name: "Norwegisches Antarktis-Territorium",
        citizenship: "149",
        iso: "AQ",
        ldkz: "AQ",
    },
    {
        name: "Oman",
        citizenship: "456",
        iso: "OM",
        ldkz: "MAO",
    },
    {
        name: "Österreich",
        citizenship: "151",
        iso: "AT",
        ldkz: "A",
    },
    {
        name: "Pakistan",
        citizenship: "461",
        iso: "PK",
        ldkz: "PK",
    },
    {
        name: "Palästinensische Gebiete",
        citizenship: "459",
        iso: "–",
        ldkz: "PSE",
    },
    {
        name: "Palau",
        citizenship: "537",
        iso: "PW",
        ldkz: "PAL",
    },
    {
        name: "Panama",
        citizenship: "357",
        iso: "PA",
        ldkz: "PA",
    },
    {
        name: "Papua-Neuguinea",
        citizenship: "538",
        iso: "PG",
        ldkz: "PNG",
    },
    {
        name: "Paraguay",
        citizenship: "359",
        iso: "PY",
        ldkz: "PY",
    },
    {
        name: "Peru",
        citizenship: "361",
        iso: "PE",
        ldkz: "PE",
    },
    {
        name: "Philippinen",
        citizenship: "462",
        iso: "PH",
        ldkz: "RP",
    },
    {
        name: "Pitcairninseln",
        citizenship: "185",
        iso: "PN",
        ldkz: "PIT",
    },
    {
        name: "Polen",
        citizenship: "152",
        iso: "PL",
        ldkz: "PL",
    },
    {
        name: "Portugal",
        citizenship: "153",
        iso: "PT",
        ldkz: "P",
    },
    {
        name: "Puerto Rico",
        citizenship: "368",
        iso: "PR",
        ldkz: "PRI",
    },
    {
        name: "Réunion",
        citizenship: "129",
        iso: "RE",
        ldkz: "REU",
    },
    {
        name: "Ruanda",
        citizenship: "265",
        iso: "RW",
        ldkz: "RWA",
    },
    {
        name: "Rumänien",
        citizenship: "154",
        iso: "RO",
        ldkz: "RO",
    },
    {
        name: "Russische Föderation",
        citizenship: "160",
        iso: "RU",
        ldkz: "RUS",
    },
    {
        name: "Salomonen",
        citizenship: "524",
        iso: "SB",
        ldkz: "SOL",
    },
    {
        name: "Sambia",
        citizenship: "257",
        iso: "ZM",
        ldkz: "Z",
    },
    {
        name: "Samoa",
        citizenship: "543",
        iso: "WS",
        ldkz: "WS",
    },
    {
        name: "San Marino",
        citizenship: "156",
        iso: "SM",
        ldkz: "RSM",
    },
    {
        name: "São Tomé und Príncipe",
        citizenship: "268",
        iso: "ST",
        ldkz: "STP",
    },
    {
        name: "Saudi-Arabien",
        citizenship: "472",
        iso: "SA",
        ldkz: "SAU",
    },
    {
        name: "Schweden",
        citizenship: "157",
        iso: "SE",
        ldkz: "S",
    },
    {
        name: "Schweiz",
        citizenship: "158",
        iso: "CH",
        ldkz: "CH",
    },
    {
        name: "Senegal",
        citizenship: "269",
        iso: "SN",
        ldkz: "SN",
    },
    {
        name: "Serbien",
        citizenship: "170",
        iso: "RS",
        ldkz: "SRP",
    },
    {
        name: "Seychellen",
        citizenship: "271",
        iso: "SC",
        ldkz: "SY",
    },
    {
        name: "Sierra Leone",
        citizenship: "272",
        iso: "SL",
        ldkz: "WAL",
    },
    {
        name: "Simbabwe",
        citizenship: "233",
        iso: "ZW",
        ldkz: "ZW",
    },
    {
        name: "Singapur",
        citizenship: "474",
        iso: "SG",
        ldkz: "SGP",
    },
    {
        name: "Slowakei",
        citizenship: "155",
        iso: "SK",
        ldkz: "SK",
    },
    {
        name: "Slowenien",
        citizenship: "131",
        iso: "SI",
        ldkz: "SLO",
    },
    {
        name: "Somalia",
        citizenship: "273",
        iso: "SO",
        ldkz: "SP",
    },
    {
        name: "Spanien",
        citizenship: "161",
        iso: "ES",
        ldkz: "E",
    },
    {
        name: "Sri Lanka",
        citizenship: "431",
        iso: "LK",
        ldkz: "CL",
    },
    {
        name: "St. Barthélemy",
        citizenship: "129",
        iso: "BL",
        ldkz: "BL",
    },
    {
        name: "St. Helena",
        citizenship: "185",
        iso: "SH",
        ldkz: "HEL",
    },
    {
        name: "St. Helena, Ascension und Tristan da Cunha",
        citizenship: "185",
        iso: "SH",
        ldkz: "HEL",
    },
    {
        name: "St. Kitts und Nevis",
        citizenship: "370",
        iso: "KN",
        ldkz: "SCN",
    },
    {
        name: "St. Lucia",
        citizenship: "366",
        iso: "LC",
        ldkz: "WL",
    },
    {
        name: "St. Martin (französischer Teil)",
        citizenship: "129",
        iso: "MF",
        ldkz: "MF",
    },
    {
        name: "St. Martin (niederländischer Teil)",
        citizenship: "148",
        iso: "SX",
        ldkz: "SX",
    },
    {
        name: "St. Pierre und Miquelon",
        citizenship: "129",
        iso: "PM",
        ldkz: "PIE",
    },
    {
        name: "St. Vincent und die Grenadinen",
        citizenship: "369",
        iso: "VC",
        ldkz: "WV",
    },
    {
        name: "Südafrika",
        citizenship: "263",
        iso: "ZA",
        ldkz: "ZA",
    },
    {
        name: "Sudan",
        citizenship: "277",
        iso: "SD",
        ldkz: "SDN",
    },
    {
        name: "Südgeorgien und die südlichen Sandwichinseln",
        citizenship: "185",
        iso: "GS",
        ldkz: "GS",
    },
    {
        name: "Südsudan",
        citizenship: "278",
        iso: "SS",
        ldkz: "SSD",
    },
    {
        name: "Suriname",
        citizenship: "364",
        iso: "SR",
        ldkz: "SME",
    },
    {
        name: "Svalbard und Jan Mayen",
        citizenship: "149",
        iso: "SJ",
        ldkz: "SJ",
    },
    {
        name: "Syrien",
        citizenship: "475",
        iso: "SY",
        ldkz: "SYR",
    },
    {
        name: "Tadschikistan",
        citizenship: "470",
        iso: "TJ",
        ldkz: "TAD",
    },
    {
        name: "Taiwan",
        citizenship: "465",
        iso: "TW",
        ldkz: "TWN",
    },
    {
        name: "Tansania",
        citizenship: "282",
        iso: "TZ",
        ldkz: "EAT",
    },
    {
        name: "Thailand",
        citizenship: "476",
        iso: "TH",
        ldkz: "T",
    },
    {
        name: "Timor-Leste",
        citizenship: "483",
        iso: "TL",
        ldkz: "OTI",
    },
    {
        name: "Togo",
        citizenship: "283",
        iso: "TG",
        ldkz: "TG",
    },
    {
        name: "Tokelau",
        citizenship: "536",
        iso: "TK",
        ldkz: "TOK",
    },
    {
        name: "Tonga",
        citizenship: "541",
        iso: "TO",
        ldkz: "TON",
    },
    {
        name: "Trinidad und Tobago",
        citizenship: "371",
        iso: "TT",
        ldkz: "TT",
    },
    {
        name: "Tristan da Cunha",
        citizenship: "185",
        iso: "SH",
        ldkz: "HEL",
    },
    {
        name: "Tschad",
        citizenship: "284",
        iso: "TD",
        ldkz: "CHD",
    },
    {
        name: "Tschechien",
        citizenship: "164",
        iso: "CZ",
        ldkz: "CZ",
    },
    {
        name: "Tunesien",
        citizenship: "285",
        iso: "TN",
        ldkz: "TN",
    },
    {
        name: "Türkei",
        citizenship: "163",
        iso: "TR",
        ldkz: "TR",
    },
    {
        name: "Turkmenistan",
        citizenship: "471",
        iso: "TM",
        ldkz: "TUR",
    },
    {
        name: "Turks- und Caicosinseln",
        citizenship: "185",
        iso: "TC",
        ldkz: "TUC",
    },
    {
        name: "Tuvalu",
        citizenship: "540",
        iso: "TV",
        ldkz: "TUV",
    },
    {
        name: "Uganda",
        citizenship: "286",
        iso: "UG",
        ldkz: "EAU",
    },
    {
        name: "Ukraine",
        citizenship: "166",
        iso: "UA",
        ldkz: "UA",
    },
    {
        name: "Ungarn",
        citizenship: "165",
        iso: "HU",
        ldkz: "H",
    },
    {
        name: "Uruguay",
        citizenship: "365",
        iso: "UY",
        ldkz: "ROU",
    },
    {
        name: "Usbekistan",
        citizenship: "477",
        iso: "UZ",
        ldkz: "USB",
    },
    {
        name: "Vanuatu",
        citizenship: "532",
        iso: "VU",
        ldkz: "VAN",
    },
    {
        name: "Vatikanstadt",
        citizenship: "167",
        iso: "VA",
        ldkz: "V",
    },
    {
        name: "Venezuela",
        citizenship: "367",
        iso: "VE",
        ldkz: "YV",
    },
    {
        name: "Vereinigte Arabische Emirate",
        citizenship: "469",
        iso: "AE",
        ldkz: "UAE",
    },
    {
        name: "Vereinigte Staaten",
        citizenship: "368",
        iso: "US",
        ldkz: "USA",
    },
    {
        name: "Vereinigtes Königreich",
        citizenship: "168",
        iso: "GB",
        ldkz: "GB",
    },
    {
        name: "Vietnam",
        citizenship: "432",
        iso: "VN",
        ldkz: "VN",
    },
    {
        name: "Wallis und Futuna",
        citizenship: "129",
        iso: "WF",
        ldkz: "WF",
    },
    {
        name: "Weihnachtsinsel",
        citizenship: "523",
        iso: "CX",
        ldkz: "CX",
    },
    {
        name: "Westsahara",
        citizenship: "252",
        iso: "EH",
        ldkz: "EH",
    },
    {
        name: "Zentralafrikanische Republik",
        citizenship: "289",
        iso: "CF",
        ldkz: "RCA",
    },
    {
        name: "Zypern",
        citizenship: "181",
        iso: "CY",
        ldkz: "CY",
    },
];
