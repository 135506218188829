import { html, css, nothing, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { Dialog } from "./dialog";
import SignaturePad from "signature_pad";
import { shared } from "../styles";

export interface SignatureDialogResult {
    src: string;
    info?: {
        firstName: string;
        lastName: string;
        address: string;
        email: string;
        phone: string;
    };
}

@customElement("ptc-contact-info-form")
export class ContactInfoForm extends LitElement {
    static styles = [shared];

    @state()
    private _address: string | null = null;

    @state()
    private _firstName: string | null = null;

    @state()
    private _lastName: string | null = null;

    @state()
    private _email: string | null = null;

    @state()
    private _phone: string | null = null;

    @property()
    public get info(): SignatureDialogResult["info"] | null {
        if (!this._formElement.reportValidity()) {
            return null;
        }

        return {
            firstName: this._firstName!,
            lastName: this._lastName!,
            address: this._address!,
            email: this._email!,
            phone: this._phone!,
        };
    }

    @query("form")
    private _formElement: HTMLFormElement;

    render() {
        const layoutClasses = "padded horizontal evenly stretching layout";

        return html`<form class="padded vertical layout">
            <h2>Kontaktdaten</h2>
            <div class="${layoutClasses}">
                <label for="firstName">Vorname</label>
                <input
                    id="firstName"
                    type="text"
                    .required=${true}
                    .value=${this._firstName}
                    @input=${(e: InputEvent) => (this._firstName = (e.target as HTMLInputElement).value)}
                />
            </div>
            <div class="${layoutClasses}">
                <label for="lastName">Nachname</label>
                <input
                    id="lastName"
                    type="text"
                    .required=${true}
                    .value=${this._lastName}
                    @input=${(e: InputEvent) => (this._lastName = (e.target as HTMLInputElement).value)}
                />
            </div>
            <div class="${layoutClasses}">
                <label for="address">Adresse</label>
                <input
                    id="address"
                    type="text"
                    .required=${true}
                    .value=${this._address}
                    @input=${(e: InputEvent) => (this._address = (e.target as HTMLInputElement).value)}
                />
            </div>
            <div class="${layoutClasses}">
                <label for="email">Email</label>
                <input
                    id="email"
                    type="email"
                    .required=${true}
                    .value=${this._email}
                    @input=${(e: InputEvent) => (this._email = (e.target as HTMLInputElement).value)}
                />
            </div>
            <div class="${layoutClasses}">
                <label for="phone">Telefon</label>
                <input
                    id="phone"
                    type="tel"
                    .required=${true}
                    .value=${this._phone ?? ""}
                    @input=${(e: InputEvent) => (this._phone = (e.target as HTMLInputElement).value)}
                />
            </div>
        </form>`;
    }
}

@customElement("ptc-signature-dialog")
export class SignatureDialog extends Dialog<void, SignatureDialogResult> {
    @query("canvas")
    private _canvas: HTMLCanvasElement;

    @property()
    public requestContactInfo = false;

    @query(".canvas-wrapper")
    private _canvasWrapper: HTMLElement;

    private _pad: SignaturePad;

    readonly preventDismiss = true;

    firstUpdated() {
        this._pad = new SignaturePad(this._canvas);
    }

    async show() {
        const promise = super.show();

        await this.updateComplete;

        const dpr = window.devicePixelRatio || 0;
        this._canvas.width = this._canvasWrapper.offsetWidth * dpr;
        this._canvas.height = this._canvasWrapper.offsetHeight * dpr;
        this._canvas.getContext("2d")!.scale(dpr, dpr);
        this._pad.clear();

        return promise;
    }

    static styles = [
        ...Dialog.styles,
        css`
            .canvas-wrapper {
                position: relative;
                padding-bottom: 50%;
            }

            canvas {
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        `,
    ];

    @query("ptc-contact-info-form")
    private _contactInfoForm?: ContactInfoForm;

    private _completeDialog() {
        const src = this._canvas.toDataURL();
        const info = (this.requestContactInfo && this._contactInfoForm?.info) || undefined;

        if (info || !this.requestContactInfo) {
            this.done({ src, info });
        }
    }

    renderContent() {
        return html`
            <div class="padded vertical layout">
                ${this.requestContactInfo ? html`<div class="border-bottom"><ptc-contact-info-form /></div>` : nothing}
                <div class="canvas-wrapper">
                    <canvas></canvas>
                </div>
                <div class="padded subtle border-top text-centering">Hier Unterschreiben</div>
            </div>
            <div class="padded evenly stretching spacing horizontal layout">
                <button class="primary" @click=${() => this._completeDialog()}>Übernehmen</button>
                <button class="transparent" @click=${() => this.done()}>Abbrechen</button>
                <button class="transparent" @click=${() => this._pad.clear()}>Zurücksetzen</button>
            </div>
        `;
    }
}
