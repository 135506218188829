const standardPatterns = {
    socialSecurityNumber: { pattern: `\\d{2} ?\\d{6} ?[A-Za-z] ?\\d{3}`, placeholder: "z.B.: 15 070649 C 103" },
    taxId: { pattern: `\\d{11}`, placeholder: "Elfstellig, nur Zahlen" },
    postalCode: `^\\d{5}$`,
    iban: `^([A-Z]{2}[ \\-]?[0-9]{2})(?=(?:[ \\-]?[A-Z0-9]){9,30}$)((?:[ \\-]?[A-Z0-9]{3,5}){2,7})([ \\-]?[A-Z0-9]{1,3})?$`,
    companyNumber: `^\\d{8}$`,
    clientNumber: `^\\d*$`,
    consultantNumber: `^\\d*$`,
};

export const patterns: Record<string, typeof standardPatterns> = {
    DE: standardPatterns,
    AT: {
        ...standardPatterns,
        socialSecurityNumber: { pattern: `\\d{4} ?\\d{6}`, placeholder: "z.B. 1234 301192" },
        taxId: { pattern: `\\d{9}`, placeholder: "Neunstellig, nur Zahlen" },
        postalCode: `^\\d{4}$`,
    },
};
