import QrScanner from "qr-scanner";
// @ts-expect-error importing a file
// eslint-disable-next-line import/no-unresolved
import QrScannerWorkerPath from "!!file-loader!qr-scanner/qr-scanner-worker.min.js";
QrScanner.WORKER_PATH = QrScannerWorkerPath;

let scanner: QrScanner | null = null;

export async function scanQR(video: HTMLVideoElement) {
    return new Promise((resolve, reject) => {
        scanner && scanner.stop();
        scanner = new QrScanner(video, (res) => {
            scanner && scanner.stop();
            resolve(res);
            scanner = null;
        });

        scanner.start().then(
            () => {},
            (err) => reject(err)
        );
    });
}

export function stopScanQR() {
    scanner && scanner.stop();
    scanner = null;
}

// let qrVideo: HTMLVideoElement;
//
// export async function scanQR(qrCanvas: HTMLCanvasElement) {
//     return new Promise<string>((resolve, reject) => {
//         const tick = async () => {
//             if (qrVideo.readyState !== qrVideo.HAVE_ENOUGH_DATA) {
//                 requestAnimationFrame(() => tick());
//                 return;
//             }
//
//             const { default: jsQR } = await import(/* webpackChunkName: "jsqr" */ "jsqr");
//
//             const canvas = qrCanvas.getContext("2d")!;
//             qrCanvas.height = qrVideo.videoHeight;
//             qrCanvas.width = qrVideo.videoWidth;
//             canvas.drawImage(qrVideo, 0, 0, qrCanvas.width, qrCanvas.height);
//             const imageData = canvas.getImageData(0, 0, qrCanvas.width, qrCanvas.height);
//             const code = jsQR(imageData.data, imageData.width, imageData.height, {
//                 inversionAttempts: "dontInvert",
//             });
//             if (code) {
//                 resolve(code.data);
//             }
//             requestAnimationFrame(() => tick());
//         };
//
//         if (!qrVideo) {
//             qrVideo = document.createElement("video");
//             qrVideo.setAttribute("playsinline", "");
//             qrVideo.setAttribute("muted", "");
//             qrVideo.setAttribute("autoplay", "");
//         }
//
//         navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: "environment" } }).then((stream) => {
//             // Use facingMode: environment to attemt to get the front camera on phones
//             qrVideo.srcObject = stream;
//             qrVideo.play();
//             requestAnimationFrame(() => tick());
//         }, reject);
//     });
// }
//
// export async function stopScanQR() {
//     const stream: MediaStream | null = qrVideo && (qrVideo.srcObject as MediaStream);
//     if (stream) {
//         for (const track of stream.getTracks()) {
//             track.stop();
//         }
//     }
//
//     qrVideo && (qrVideo.srcObject = null);
// }
