import {
    Company,
    EmploymentType,
    Form,
    FormField,
    FormTableHeaders,
    Gender,
    genderLabel,
    getEmploymentTypes,
} from "./model";
import { countries } from "./countries";
import { patterns } from "./validation";

export function createTableRow(headers: FormTableHeaders[]): FormField[] {
    const defaultValue = (header: FormTableHeaders) => {
        switch (header.type) {
            case "string":
                return "";
            case "number":
                return 0;
            case "date":
                return "";
            case "boolean":
                return false;
            default:
                return "";
        }
    };

    return headers.map((header) => ({
        name: header.name,
        label: header.label,
        type: header.type,
        required: header.required,
        value: defaultValue(header),
    }));
}

export function getOnboardingForm(company: Company) {
    const pvChildHeaders: FormTableHeaders[] = [
        { name: "firstName", label: "Vorname", type: "string", required: true },
        { name: "lastName", label: "Nachname", type: "string", required: true },
        { name: "birthdate", label: "Geburtsdatum", type: "date", required: true },
    ];

    const otherEmploymentHeaders: FormTableHeaders[] = [
        { name: "nameEmployer", label: "Name des Arbeitgebers", type: "string", required: true },
        { name: "addressEmployer", label: "Adresse des Arbeitgebers", type: "string", required: true },
        { name: "startDate", label: "Eintrittsdatum", type: "date", required: true },
        { name: "endDate", label: "Austrittsdatum", type: "date", required: true },
        { name: "workingDays", label: "Anzahl Arbeitstage", type: "number", required: true },
    ];

    const countrySpecificPatterns = patterns[company.country] ?? patterns.DE;
    return new Form({
        name: "Personalfragebogen",
        description: "Die folgende Erklärung dient der Beschaffung der erforderlichen Daten zur Lohnabrechnung.",
        requiresEmployeeSignature: true,
        requiresManagerSignature: true,
        considerCaretakerSignatureIfUnderage: true,
        sections: [
            {
                title: "Beschäftigung",
                fields: [
                    {
                        name: "company",
                        label: "Unternehmen",
                        type: "string",
                        value: company.name,
                        required: true,
                        managerOnly: true,
                    },
                    {
                        name: "venue",
                        label: "Betrieb",
                        type: "string",
                        value: company.venues?.[0]?.name || "",
                        required: true,
                        managerOnly: true,
                        options: company.venues?.length
                            ? company.venues.map((venue) => ({
                                  label: venue.name,
                                  value: venue.name,
                              }))
                            : undefined,
                    },
                    {
                        name: "employmentType",
                        label: "Beschäftigungsverhältnis",
                        type: "number",
                        value: EmploymentType.Regular,
                        required: true,
                        managerOnly: true,
                        options: getEmploymentTypes().map(({ type, label }) => ({
                            label,
                            value: type,
                        })),
                    },
                    {
                        name: "position",
                        label: "Ausgeübte Tätigkeit",
                        type: "string",
                        value: "",
                        required: true,
                        managerOnly: true,
                    },
                    {
                        name: "employmentStart",
                        label: "Eintrittsdatum",
                        type: "date",
                        value: "",
                        required: true,
                        managerOnly: true,
                    },
                    {
                        name: "employmentEnd",
                        label: "Austrittsdatum",
                        description: "Bei unbefristetem Vertrag leer lassen.",
                        type: "date",
                        value: "",
                        required: false,
                        managerOnly: true,
                    },
                ],
            },
            {
                title: "Vereinbarte Vergütung",
                fields: [
                    {
                        name: "paymentType",
                        label: "Art der Vergütung",
                        type: "string",
                        value: "",
                        required: true,
                        options: [
                            { label: "Monatliches Gehalt", value: "monthly" },
                            { label: "Stundenlohn", value: "hourly" },
                        ],
                        managerOnly: true,
                    },
                    {
                        name: "monthlySalary",
                        label: "Gehalt (€)",
                        type: "number",
                        step: "0.01",
                        required: true,
                        value: "",
                        condition: { field: "paymentType", value: "monthly" },
                        managerOnly: true,
                    },
                    {
                        name: "hourlyRate",
                        label: "Stundenlohn (€)",
                        type: "number",
                        step: "0.01",
                        required: true,
                        value: "",
                        condition: { field: "paymentType", value: "hourly" },
                        managerOnly: true,
                    },
                ],
            },
            {
                title: "Vereinbarte Arbeitszeit",
                fields: [
                    {
                        name: "hoursPerWeek",
                        label: "Stunden / Woche",
                        step: "0.001",
                        type: "number",
                        required: true,
                        value: "",
                        managerOnly: true,
                    },
                ],
            },
            {
                title: "Weitere Beschäftigungsverhältnisse",
                condition: { field: "employmentType", value: EmploymentType.ShortTerm },
                fields: [
                    {
                        name: "otherEmployments",
                        label: "Bestanden weitere befristete Beschäftigungsverhältnisse?",
                        type: "boolean",
                        value: false,
                    },
                    {
                        name: "otherEmploymentsInfo",
                        type: "table",
                        label: "",
                        description:
                            "Alle befristeten Beschäftigungen im Kalenderjahr (auch anteilig) dürfen in Summe die 70 Arbeitstage nicht überschreiten.",
                        condition: { field: "otherEmployments", value: true },
                        headers: otherEmploymentHeaders,
                        data: [createTableRow(otherEmploymentHeaders)],
                    },
                ],
            },
            {
                title: "Persönliche Angaben",
                fields: [
                    {
                        name: "title",
                        label: "Titel",
                        type: "string",
                        value: "",
                        required: false,
                        mapsTo: "title",
                    },
                    {
                        name: "firstName",
                        label: "Vorname",
                        type: "string",
                        value: "",
                        required: true,
                        mapsTo: "firstName",
                    },
                    {
                        name: "lastName",
                        label: "Nachname",
                        type: "string",
                        value: "",
                        required: true,
                        mapsTo: "lastName",
                    },
                    {
                        name: "familyStatus",
                        label: "Familienstand",
                        type: "string",
                        value: "",
                        required: true,
                        options: [
                            { label: "Ledig", value: "single" },
                            { label: "Verheiratet", value: "married" },
                            { label: "Verwitwet", value: "widowed" },
                            { label: "Geschieden", value: "divorced" },
                            { label: "Eingetragene Lebenspartnerschaft", value: "registeredLifePartnership" },
                            {
                                label: "Eingetragene Lebenspartnerschaft verstorben",
                                value: "registeredLifePartnershipDeceased",
                            },
                            {
                                label: "Eingetragene Lebenspartnerschaft aufgehoben",
                                value: "registeredLifePartnershipCancelled",
                            },
                        ],
                    },
                    {
                        name: "address",
                        label: "Adresse",
                        type: "string",
                        value: "",
                        required: true,
                        mapsTo: "address",
                    },
                    {
                        name: "postalCode",
                        label: "Postleitzahl",
                        type: "string",
                        value: "",
                        required: true,
                        mapsTo: "postalCode",
                        pattern: countrySpecificPatterns.postalCode,
                    },
                    {
                        name: "city",
                        label: "Ort",
                        type: "string",
                        value: "",
                        required: true,
                        mapsTo: "city",
                    },
                    {
                        name: "phone",
                        label: "Telefon",
                        type: "string",
                        value: "",
                        required: true,
                        mapsTo: "phone",
                    },
                    {
                        name: "email",
                        label: "Email",
                        type: "string",
                        value: "",
                        required: true,
                        mapsTo: "email",
                    },
                    {
                        name: "birthday",
                        label: "Geburtstag",
                        type: "date",
                        value: "",
                        required: true,
                        mapsTo: "birthday",
                    },
                    {
                        name: "gender",
                        label: "Geschlecht",
                        type: "string",
                        value: "",
                        required: true,
                        options: Object.values(Gender).map((gender: Gender) => ({
                            label: genderLabel(gender),
                            value: gender,
                        })),
                        mapsTo: "gender",
                    },
                    {
                        name: "birthName",
                        label: "Geburtsname",
                        type: "string",
                        value: "",
                        required: false,
                        description: "(Falls Abweichend)",
                        mapsTo: "birthName",
                    },
                    {
                        name: "birthCity",
                        label: "Geburtsort",
                        type: "string",
                        value: "",
                        required: true,
                        mapsTo: "birthCity",
                    },
                    {
                        name: "birthCountry",
                        label: "Geburtsland",
                        type: "string",
                        value: "DE",
                        required: true,
                        options: countries.map((c) => ({
                            label: c.name,
                            value: c.iso,
                        })),
                        mapsTo: "birthCountry",
                    },
                    {
                        name: "nationality",
                        label: "Nationalität",
                        type: "string",
                        value: "DE",
                        required: true,
                        options: countries.map((c) => ({
                            label: c.name,
                            value: c.iso,
                        })),
                        mapsTo: "nationality",
                    },
                ],
            },
            {
                title: "Steuerliche Angaben",
                fields: [
                    {
                        name: "taxId",
                        label: "Steuer-ID",
                        description: "Bestehend aus elf Ziffern, keine Buchstaben.",
                        type: "string",
                        pattern: countrySpecificPatterns.taxId.pattern,
                        value: "",
                        required: true,
                        mapsTo: "taxId",
                    },
                    {
                        name: "socialSecurityNumber",
                        label: "SV-Nummer",
                        description: 'Auch "Rentenversicherungsnummer (RVNR)" - Beispiel: 15 070649 C 103',
                        type: "string",
                        pattern: countrySpecificPatterns.socialSecurityNumber.pattern,
                        value: "",
                        required: true,
                        mapsTo: "socialSecurityNumber",
                    },
                    {
                        name: "mainJob",
                        label: "Diese Beschäftigung ist meine Hauptbeschäftigung",
                        type: "boolean",
                        value: false,
                        required: false,
                    },
                    {
                        name: "pvBonusChildren",
                        label: "Berücksichtigungsfähige Kinder für PV- Zuschlag",
                        type: "string",
                        value: "",
                        options: [
                            { label: "Ja", value: "yes" },
                            { label: "Nein", value: "nein" },
                            { label: "Unbestimmt", value: "unbestimmt" },
                        ],
                        required: true,
                    },
                    {
                        name: "pvChildInfo",
                        type: "table",
                        label: "",
                        description: "Der zu zahlende Beitrag richtet sich nach der Anzahl der Kinder unter 25 Jahren.",
                        condition: { field: "pvBonusChildren", value: "yes" },
                        max: "5",
                        headers: pvChildHeaders,
                        data: [createTableRow(pvChildHeaders)],
                    },
                    {
                        name: "taxClass",
                        label: "Steuerklasse",
                        type: "string",
                        value: "",
                        options: [
                            { label: "Steuerklasse 1", value: "1" },
                            { label: "Steuerklasse 2", value: "2" },
                            { label: "Steuerklasse 3", value: "3" },
                            { label: "Steuerklasse 4", value: "4" },
                            { label: "Steuerklasse 5", value: "5" },
                            { label: "Steuerklasse 6", value: "6" },
                        ],
                        required: true,
                    },
                    {
                        name: "childrenFreeAmount",
                        label: "Kinderfreibetrag",
                        type: "string",
                        value: "",
                        required: false,
                    },
                    {
                        name: "confession",
                        label: "Konfession",
                        type: "string",
                        value: "",
                        required: false,
                    },
                ],
            },
            {
                title: "Bankverbindung",
                fields: [
                    {
                        name: "payByBankTransfer",
                        label: "Zahlung per Banküberweisung",
                        type: "boolean",
                        required: false,
                        value: false,
                    },
                ],
            },
            {
                condition: {
                    field: "payByBankTransfer",
                    value: true,
                },
                fields: [
                    {
                        name: "bankAccountOwner",
                        label: "Kontoinhaber",
                        type: "string",
                        required: true,
                        value: "",
                    },
                    {
                        name: "iban",
                        label: "IBAN",
                        type: "string",
                        required: true,
                        value: "",
                        pattern: countrySpecificPatterns.iban,
                    },
                    {
                        name: "bankName",
                        label: "Name der Bank",
                        type: "string",
                        required: true,
                        value: "",
                    },
                ],
            },
            {
                title: "Ausbildung",
                fields: [
                    {
                        name: "schoolEducation",
                        label: "Höchster Schulabschluss",
                        type: "string",
                        required: true,
                        value: "",
                        options: [
                            { label: "ohne Schulabschluss", value: "ohne Schulabschluss" },
                            { label: "Haupt - /Volksschulabschluss", value: "Haupt - /Volksschulabschluss" },
                            {
                                label: "Mittlere Reife oder gleichwert. Abschluss",
                                value: "Mittlere Reife oder gleichwert. Abschluss",
                            },
                            { label: "Abitur / Fachabitur", value: "Abitur / Fachabitur" },
                        ],
                    },
                    {
                        name: "professionalEducation",
                        label: "Höchste berufliche Ausbildung",
                        type: "string",
                        required: true,
                        value: "",
                        options: [
                            { label: "ohne beruflichen Abschluss", value: "ohne beruflichen Abschluss" },
                            {
                                label: "Abschluss einer anerkannten Berufsausbildung",
                                value: "Abschluss einer anerkannten Berufsausbildung",
                            },
                            {
                                label: "Meister-/Techniker oder gleichw. Fachschulabschluss",
                                value: "Meister-/Techniker oder gleichw. Fachschulabschluss",
                            },
                            { label: "Bachelor", value: "Bachelor" },
                            {
                                label: "Diplom/Magister/Master/Staatsexamen",
                                value: "Diplom/Magister/Master/Staatsexamen",
                            },
                            { label: "Promotion", value: "Promotion" },
                        ],
                    },
                ],
            },
            {
                title: "Krankenversicherungsstatus",
                fields: [
                    {
                        name: "ensuranceType",
                        label: "Art der Versicherung",
                        type: "string",
                        required: true,
                        value: "",
                        options: [
                            { label: "gesetzlich", value: "gesetzlich" },
                            { label: "privat", value: "privat" },
                            { label: "im Ausland versichert", value: "im Ausland versichert" },
                            { label: "sonstiges", value: "sonstiges" },
                        ],
                    },
                    {
                        name: "otherEnsuranceType",
                        label: "Sonstige Versicherung",
                        type: "string",
                        required: true,
                        value: "",
                        condition: { field: "ensuranceType", value: "sonstiges" },
                    },
                    {
                        name: "lastEnsurance",
                        label: "Letzte gesetzliche Versicherung",
                        type: "string",
                        required: true,
                        value: "",
                        condition: { field: "ensuranceType", value: "privat" },
                    },
                    {
                        name: "ensurerName",
                        label: "Name der Krankenkasse",
                        type: "string",
                        required: true,
                        value: "",
                    },
                ],
            },
        ],
    });
}

export function getDefaultForms(company?: Company) {
    return company ? [getOnboardingForm(company)] : [];
}
