import { RequestSetPasswordParams } from "@pentacode/core/src/api";
import { html, css } from "lit";
import { customElement, query } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert } from "./alert-dialog";
import "./scroller";

@customElement("ptc-forgot-password-dialog")
export class ForgotPasswordDialog extends Dialog<
    {
        email: string;
        scope: string;
    },
    void
> {
    readonly preventDismiss = true;

    private _scope?: string;

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='email']")
    private _emailInput: HTMLInputElement;

    async show({ email, scope }: { email?: string; scope?: string } = {}) {
        const promise = super.show();
        await this.updateComplete;
        this._emailInput.value = email || "";
        this._scope = scope;
        return promise;
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        this.loading = true;
        const email = new FormData(this._form).get("email") as string;

        try {
            await app.api.requestSetPassword(new RequestSetPasswordParams({ email, scope: this._scope }));
            this.loading = false;
            this.done();
            void alert(`Eine Email mit weiteren Anweisungen wurde an ${email} gesendet.`, {
                title: "Sie haben Post!",
                icon: "envelope",
            });
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            this._show();
            this.loading = false;
        }
    }

    static styles = [
        ...Dialog.styles,
        css`
            h1 {
                margin: 0;
            }
        `,
    ];

    renderContent() {
        return html`
            <div class="padded spacing vertical layout">
                <h1 class="margined text-centering">Passwort Zurücksetzen</h1>

                <form @submit=${this._submit}>
                    <div class="field">
                        <input type="email" name="email" placeholder="Emailadresse Eingeben" required />
                    </div>

                    <div class="horizontal spacing evenly stretching layout">
                        <button class="primary">Bestätigen</button>
                        <button type="button" @click=${() => this.done()}>Abbrechen</button>
                    </div>
                </form>
            </div>
        `;
    }
}
