import { LitElement, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { fileIcon, MimeType } from "@pentacode/core/src/model";

@customElement("ptc-file-input")
export class FileInput extends LitElement {
    @property({ attribute: false })
    file: File | null = null;

    @property({ type: Boolean })
    required = false;

    @property({ attribute: false })
    acceptTypes: MimeType[] = Object.values(MimeType);

    @property({ type: Boolean })
    readonly = false;

    selectFile() {
        this._input.click();
    }

    @query("input[type='file']")
    private _input: HTMLInputElement;

    private async _fileSelected(e: Event) {
        e.stopPropagation();
        this.file = this._input.files?.[0] || null;
        this.dispatchEvent(new Event("change"));
    }

    updated(changes: Map<string, unknown>) {
        if (changes.has("file") && !this.file) {
            this._input.value = "";
        }
    }

    createRenderRoot() {
        return this;
    }

    render() {
        const icon = this.file ? fileIcon(this.file.type as MimeType) : "file-slash";
        return html`
            <div class="box horizontal center-aligning layout relative ${this.readonly ? "" : "click"}">
                <i class="huge ${icon}"></i>
                <div class="right-padded stretch ellipsis">
                    ${this.file?.name || html`<span class="subtle">Nichts Gewählt</span>`}
                </div>
                <div class="border-left" style="padding: 0.6em" ?hidden=${this.readonly}>Datei Wählen...</div>
                <input
                    ?disabled=${this.readonly}
                    type="file"
                    style="position: absolute; width: 100%; height: 100%; box-sizing: border-box; opacity: 0; cursor: pointer;"
                    @change=${this._fileSelected}
                    accept=${this.acceptTypes.join(",")}
                    ?required=${this.required}
                />
            </div>
        `;
    }
}
