import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { DocumentTag } from "@pentacode/core/src/model";
import { app } from "../init";
import { shared } from "../styles";

@customElement("ptc-document-tags-input")
export class DocumentTagsInput extends LitElement {
    @property({ attribute: false })
    tags: DocumentTag[] = [];

    @state()
    _showResults: boolean = false;

    @query("input")
    private _input: HTMLInputElement;

    @query(".results")
    private _results: HTMLDivElement;

    connectedCallback(): void {
        super.connectedCallback();
        this.addEventListener("focus", () => this._focus());
        this.addEventListener("blur", () => this._blur());
    }

    private _keydown(e: KeyboardEvent) {
        if (e.key === "Enter") {
            this._addTag(new DocumentTag({ name: this._input.value }));
        }
    }

    private async _addTag(tag: DocumentTag) {
        if (!tag || this.tags.some((t) => t.name === tag.name)) {
            return;
        }
        this.tags.push(tag);
        this._input.value = "";
        this._showResults = false;
        this.requestUpdate();
        await this.updateComplete;
        this._input.focus();
    }

    private _tagClicked(tag: DocumentTag) {
        this.tags = this.tags.filter((t) => t.name !== tag.name);
    }

    private _hideResultsTimeout: number;

    private _focus() {
        window.clearTimeout(this._hideResultsTimeout);
        this._results.style.display = "";
        this.classList.add("focused");
    }

    private _blur() {
        this.classList.remove("focused");
        this._hideResultsTimeout = window.setTimeout(() => (this._results.style.display = "none"), 100);
    }

    static styles = [
        shared,
        css`
            :host {
                display: flex;
                flex-direction: row;
                gap: 0.3em;
                flex-wrap: wrap;
                border: solid 1px var(--shade-2);
                border-radius: 0.5em;
                position: relative;
                z-index: 1;
                overflow: visible;
                padding: 0.3em;
            }

            input {
                padding: 0.3em;
                border: none;
                background: none;
                width: 100%;
            }

            :host(.focused) {
                border-color: var(--color-primary);
            }

            :host(:not(.focused)) i.plus {
                opacity: 0.5;
            }

            .results {
                position: absolute;
                background: var(--color-bg);
                border: solid 1px var(--shade-2);
                width: 100%;
                border-radius: 0.5em;
                margin-top: 0.2em;
                box-sizing: border-box;
                transition: pointer-events 0.5s;
                max-width: 15em;
            }

            .add-tag {
                overflow: visible;
                position: relative;
                font-size: 0.9em;
            }

            .pill:not(:hover) > .remove-icon {
                display: none;
            }
        `,
    ];

    render() {
        const existingTags = app.company?.documentTags || [];
        const value = this._input?.value || "";
        const results = existingTags.filter(
            (t) =>
                !this.tags.some((t2) => t2.name === t.name) &&
                t.name !== value &&
                t.name.toLowerCase().startsWith(value)
        );
        if (value) {
            results.push(new DocumentTag({ name: value, color: "" }));
        }

        return html`
            ${this.tags.map(
                (tag) => html`
                    <div
                        class="smaller pill click"
                        @click=${() => this._tagClicked(tag)}
                        style="--color-highlight: ${tag.color}"
                    >
                        <i class="tag"></i>
                        ${tag.name}
                        <i class="smaller times remove-icon"></i>
                    </div>
                `
            )}

            <div class="smaller add-tag">
                <div class="center-aligning horizontal layout">
                    <i class="plus" @click=${() => this._input.focus()}></i>

                    <input
                        class="stretch"
                        placeholder="Hinzufügen..."
                        @keydown=${this._keydown}
                        @input=${() => this.requestUpdate()}
                    />
                </div>

                <div class="half-padded results vertical layout" style="display: none">
                    ${results.length
                        ? results.map(
                              (res) => html`
                                  <button
                                      class="text-left-aligning slim transparent"
                                      @click=${() => this._addTag(res)}
                                      style="--color-highlight: ${res.color}"
                                  >
                                      <i class="tag"></i>${res.name}
                                  </button>
                              `
                          )
                        : html` <div class="smaller padded subtle">Tippen zum Filtern / neue Kategorie anlegen</div> `}
                </div>
            </div>
        `;
    }
}
