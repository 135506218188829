import Logo from "../../img/logo.svg";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { config, shared } from "../../styles";
import { StateMixin } from "../../mixins/state";
import { Routing } from "../../mixins/routing";
import { app } from "../../init";
import { singleton } from "../../lib/singleton";
import { alert } from "../alert-dialog";
import "../spinner";
import { ForgotPasswordDialog } from "../forgot-password-dialog";

@customElement("ptc-staff-login")
export class LoginView extends Routing(StateMixin(LitElement)) {
    readonly routePattern = /^login$/;

    @state()
    private _companyId?: number;

    @state()
    private _loading = false;

    @singleton("ptc-forgot-password-dialog")
    private _forgotPasswordDialog: ForgotPasswordDialog;

    @query("input[name='email']")
    private _emailInput: HTMLInputElement;

    async handleRoute(_args: any, { c, email }: { c?: string; email?: string }) {
        if (app.account) {
            this.redirect("");
            return;
        }

        this._companyId = c ? Number(c) : undefined;

        const loadingScreen = document.querySelector(".loading-screen") as HTMLElement;
        loadingScreen && (loadingScreen.style.display = "none");

        await this.updateComplete;
        this._emailInput && (this._emailInput.value = email || "");
    }

    private async _loginWithPassword(e: Event) {
        e.preventDefault();
        const form = e.target as HTMLFormElement;

        const data = new FormData(form);

        const email = data.get("email") as string;
        const password = data.get("password") as string;

        this._loading = true;

        try {
            await app.login({
                email,
                password,
                scope: "staff",
                company: this._companyId,
            });
            const { next, ...params } = this.router.params;
            this.router.go(next || "", params, true);
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _forgotPassword() {
        void this._forgotPasswordDialog.show({ email: this._emailInput && this._emailInput.value, scope: "staff" });
    }

    static styles = [
        config,
        shared,
        css`
            :host {
                background: #fafafa;
            }

            .logo {
                width: 20em;
            }

            .wrapper {
                width: 100%;
                max-width: 25em;
            }
        `,
    ];

    render() {
        return html`
            <div class="fullbleed vertical centering layout">
                <img class="margined logo" src=${Logo} />

                <div class="wrapper">
                    <div class="text-centering double-padded">
                        Willkommen bei der <strong>Pentacode Mitarbeiter-App</strong>! Bitte melden Sie sich mit Ihrer
                        Email und Ihrem Passwort an.
                    </div>

                    <form class="card double-margined double-padded vertical layout" @submit=${this._loginWithPassword}>
                        <div class="field bottom-margined">
                            <input name="email" type="email" required placeholder="Emailadresse" />
                        </div>

                        <div class="field bottom-margined">
                            <input name="password" type="password" required placeholder="Passwort" />
                        </div>

                        <button class="primary">Einloggen</button>
                    </form>

                    <div class="centering horizontal layout">
                        <button class="small transparent forgot-password" @click=${this._forgotPassword}>
                            Passwort Vergessen?
                        </button>
                    </div>
                </div>
            </div>

            <div class="fullbleed centering layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
