import { html, css, TemplateResult } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { scanQR, stopScanQR } from "../lib/qr";
import { mixins } from "../styles";
import { alert } from "./alert-dialog";
import { Dialog } from "./dialog";
import "./spinner";

@customElement("ptc-qr-scanner")
export class QRScanner extends Dialog<{ message?: string | TemplateResult } | undefined, string> {
    readonly hideApp = true;

    @property()
    message?: string | TemplateResult = "";

    @query("video")
    private _video: HTMLVideoElement;

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                ${mixins.fullbleed()};
                border-radius: 0;
                max-width: 100%;
                display: flex;
                flex-direction: column;
                background: transparent;
            }

            header {
                flex: none;
                box-shadow: rgb(0 0 0 / 30%) 0 1px 10px -5px;
                position: relative;
                z-index: 1;
            }

            .dialog-scrim {
                opacity: 1 !important;
            }

            .seeker {
                ${mixins.fullbleed()};
                width: 300px;
                height: 300px;
                border: solid 0.2em;
                box-shadow: var(--color-bg) 0 0 0 0.2em;
                border-radius: 2em;
                margin: auto;
            }

            video {
                object-fit: cover;
            }

            .spinner {
                z-index: -1;
            }

            .msg {
                position: absolute;
                left: 1em;
                bottom: 1em;
                right: 1em;
                z-index: 1;
            }
        `,
    ];

    renderContent() {
        return html`
            <header class="background center-aligning padded horizontal layout">
                <i class="large left-margined qrcode"></i>
                <div class="padded large stretch">QR-Code Scannen</div>
                <button class="large icon transparent" @click=${() => this.done()}>
                    <i class="times"></i>
                </button>
            </header>
            <video class="stretch"></video>
            <div class="seeker"></div>
            ${this.message ? html` <div class="double-padded text-centering card msg">${this.message}</div> ` : ""}
            <div class="fullbleed centering layout spinner">
                <ptc-spinner .active=${this.isShowing}></ptc-spinner>
            </div>
        `;
    }

    async show({ message }: { message?: string | TemplateResult } = {}) {
        const promise = super.show();
        this.message = message;
        await this.updateComplete;
        scanQR(this._video).then(
            (res: string) => this.done(res),
            (err: string) => {
                this.done();
                if (err === "Camera not found.") {
                    void alert(
                        "Die App konnte nicht auf die Kamera zugreifen. Bitte stellen Sie sicher, dass in Ihren " +
                            "Browsereinstellungen die Berechtigung für die Kamera freigegeben ist.",
                        {
                            title: "Kamera nicht verfügbar",
                            type: "warning",
                        }
                    );
                } else {
                    void alert("Scan fehlgeschlagen. Error: " + err.toString(), {
                        type: "warning",
                    });
                }
            }
        );
        return promise;
    }

    done(data?: string) {
        stopScanQR();
        super.done(data);
    }
}
