import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { Employee, EmployeeStatus, employeeStatusColor, employeeStatusLabel } from "@pentacode/core/src/model";
import { StateMixin } from "../mixins/state";
import { app } from "../init";
import { shared } from "../styles";
import "./virtual-list";
import "./avatar";
import { Checkbox } from "./checkbox";

@customElement("ptc-employee-list")
export class EmployeeList extends StateMixin(LitElement) {
    @property({ attribute: false })
    employee: Employee | null = null;

    @state()
    private _filterString = "";

    @state()
    private _employees: Employee[] = [];

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("click", (e: MouseEvent) => e.stopPropagation());
    }

    clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    focus() {
        this._filterInput.focus();
    }

    appLoaded() {
        this._applyFilter();
    }

    private _selectEmployee(employee: Employee) {
        this.employee = employee;
        this.dispatchEvent(new CustomEvent("change", { detail: { employee } }));
    }

    private _updateFilters() {
        this._filterString = this._filterInput.value;
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }

            i.filter-icon {
                pointer-events: auto !important;
            }

            .filter-icon:hover {
                cursor: pointer;
                opacity: 0.7;
            }

            .filter-icon:active,
            .filter-icon[active] {
                color: var(--color-primary);
            }

            .filter-item {
                padding: 0.4em 0.7em;
                border-radius: var(--border-radius);
            }

            .vertical.tabs button {
                border-radius: 0px;
                padding: 0.5em;
            }

            .vertical.tabs button:not(:last-child) {
                border-bottom: solid 1px var(--shade-2);
            }
        `,
    ];

    updated(changes: Map<string, unknown>) {
        if (changes.has("_filterString")) {
            this._applyFilter();
        }
    }

    private _applyFilter() {
        this._employees = app.accessibleEmployees.filter(
            (e) =>
                `${e.name} ${e.staffNumber}`.toLowerCase().includes(this._filterString.toLocaleLowerCase()) &&
                (this._filterString || e.active) &&
                e.companyId === app.company?.id &&
                app.hasPermissionForEmployee(e)
        );
    }

    public get hasNext() {
        return this._employees.findIndex((e) => e.id === this.employee?.id) < this._employees.length - 1;
    }

    public selectNext() {
        const nextIndex = this._employees.findIndex((e) => e.id === this.employee?.id) + 1;
        if (nextIndex < this._employees.length) {
            this._selectEmployee(this._employees[nextIndex]);
        }
    }

    public get hasPrevious() {
        return this._employees.findIndex((e) => e.id === this.employee?.id) > 0;
    }

    public selectPrevious() {
        const previousIndex = this._employees.findIndex((e) => e.id === this.employee?.id) - 1;
        if (previousIndex >= 0) {
            this._selectEmployee(this._employees[previousIndex]);
        }
    }

    render() {
        const employees = this._employees;

        return html`
            <div class="fullbleed vertical layout">
                <div class="margined filter-input right icon input">
                    <input
                        id="filterInput"
                        type="text"
                        placeholder="Suchen..."
                        @input=${this._updateFilters}
                        autocomplete="off"
                    />
                    <i class="${this._filterString ? "times click" : "search"} icon" @click=${this.clearFilter}></i>
                </div>

                <ptc-virtual-list
                    class="stretch relative"
                    .data=${employees}
                    .renderItem=${(e: Employee) => html`
                        <div
                            class="padded spacing border-bottom horizontal start-aligning layout click hover"
                            @click=${() => this._selectEmployee(e)}
                            ?active=${this.employee && this.employee.id === e.id}
                        >
                            <ptc-avatar .employee=${e} class="smaller"></ptc-avatar>
                            <div class="stretch collapse">
                                <div class="horizonal center-aligning spacing layout">
                                    <div class="ellipsis stretch">${e.lastName}, ${e.firstName}</div>
                                    ${e.staffNumber ? html`<div class="tiny subtle">#${e.staffNumber}</div>` : ""}
                                </div>

                                <div class="tinier top-margined spacing  horizontal layout">
                                    ${e.status !== EmployeeStatus.Active
                                        ? html`
                                              <div class="${employeeStatusColor(e.status)} inverted pill">
                                                  ${employeeStatusLabel(e.status)}
                                              </div>
                                          `
                                        : ""}
                                    ${e.positions.length
                                        ? e.positions
                                              .slice(0, 1)
                                              .map(
                                                  (position) => html`
                                                      <div
                                                          class="pill ellipsis"
                                                          style="--color-highlight: ${app.getPositionColor(
                                                              position
                                                          )}; max-width: 8em;"
                                                      >
                                                          ${position.name}
                                                      </div>
                                                  `
                                              )
                                        : html` <div class="pill ellipsis">Ohne Arbeitsbereich</div> `}
                                    ${e.positions.length > 1
                                        ? html` <div class="pill">+ ${e.positions.length - 1}</div> `
                                        : ""}
                                </div>
                            </div>
                        </div>
                    `}
                >
                </ptc-virtual-list>
            </div>
        `;
    }
}
