import { LitElement, css, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import * as pdfjsLib from "pdfjs-dist";
import "./scroller";
import { shared } from "../styles";
import { alert } from "./alert-dialog";

pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

@customElement("ptc-pdf-viewer")
export class PDFViewer extends LitElement {
    @property()
    src = "";

    @state()
    private _loading = false;

    @query(".pages")
    private _pages: HTMLElement;

    updated(changes: Map<string, unknown>) {
        if (changes.has("src")) {
            this._loadPDF();
        }
    }

    private async _loadPDF() {
        this._loading = true;

        this._pages.innerHTML = "";

        try {
            const pdf = await pdfjsLib.getDocument(this.src).promise;
            for (let i = 1; i <= pdf.numPages; i++) {
                const page = await pdf.getPage(i);
                const viewport = page.getViewport({ scale: 3 });
                const canvas = document.createElement("canvas");
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                this._pages.appendChild(canvas);
                page.render({ canvasContext: canvas.getContext("2d")!, viewport });
            }
        } catch (e) {
            void alert(
                "Es ist ein Fehler beim Laden des PDFs aufgetreten. Falls dieser Fehler regelmäßig auftritt, wenden Sie sich bitte an unseren Support.",
                { title: "Fehler beim Laden des PDFs", type: "warning" }
            );
        }

        this._loading = false;
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                position: relative;
                background: rgba(0, 0, 0, 0.6);
            }

            .pages {
                max-width: 60em;
                margin: 0 auto;
            }

            canvas {
                border-radius: 0.5em;
                box-shadow: rgba(0, 0, 0, 0.3) 0 0 5px;
            }
        `,
    ];

    render() {
        return html`
            <ptc-scroller class="fullbleed">
                <div class="padded spacing vertical layout pages"></div>
            </ptc-scroller>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
