import "./drawer";
import { TimeInput } from "./time-input";
import { html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import {
    Availability,
    availabilityColor,
    availabilityIcon,
    availabilityLabel,
    AvailabilityStatus,
    Repeat,
} from "@pentacode/core/src/model";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert, confirm } from "./alert-dialog";
import "./popover";
import { DateInput } from "./date-input";
import { formatDate, formatWeekDayShort } from "@pentacode/core/src/util";
import { Checkbox } from "./checkbox";
import { live } from "lit/directives/live.js";

@customElement("ptc-availability-dialog")
export class AvailabilityDialog extends Dialog<Availability, boolean> {
    readonly preventDismiss = true;

    @query("ptc-time-input[name='start']")
    private _startInput: TimeInput;

    @query("ptc-time-input[name='end']")
    private _endInput: TimeInput;

    @query("textarea[name='comment']")
    private _commentInput: HTMLTextAreaElement;

    @query("select[name='repeats']")
    private _repeatsSelect: HTMLSelectElement;

    @state()
    private _availability: Availability;

    @state()
    private _fullDay = true;

    @state()
    private _status: AvailabilityStatus;

    async show(av?: Availability) {
        const promise = super.show();

        if (!av) {
            return;
        }

        this._availability = av;
        this._status = this._availability.status;
        this._fullDay = !this._availability.start && !this._availability.end;

        await this.updateComplete;

        this._startInput.value = this._availability.start || "";
        this._endInput.value = this._availability.end || "";
        this._repeatsSelect.value = this._availability.repeats;
        this._commentInput.value = this._availability.comment;

        // TODO: fill form

        return promise;
    }

    private _fullDayToggled(e: Event) {
        this._fullDay = (e.target as Checkbox).checked;
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        const av = this._availability;

        av.start = this._fullDay ? null : this._startInput.value;
        av.end = this._fullDay ? null : this._endInput.value;
        av.comment = this._commentInput.value;
        av.repeats = this._repeatsSelect.value as Repeat;
        av.status = this._status;

        this.loading = true;

        try {
            av.id ? await app.api.updateAvailability(av) : await app.api.createAvailability(av);
            this.done(true);
        } catch (err) {
            this.hide();
            await alert(err.message, { type: "warning" });
            void this.show();
        }

        this.loading = false;
    }

    private async _delete() {
        this.hide();
        if (
            !(await confirm("Wollen Sie diese Verfügbarkeit wirklich entfernen?", "Entfernen", "Abbrechen", {
                title: "Verfügbarkeit Entfernen",
                type: "destructive",
            }))
        ) {
            this._show();
            return;
        }
        this._show();
        this.loading = true;
        try {
            await app.api.deleteAvailability(this._availability.id);
            this.done(true);
        } catch (err) {
            this.hide();
            await alert(err.message, { type: "warning" });
            this._show();
        }
        this.loading = false;
    }

    static styles = [
        ...Dialog.styles,
        DateInput.styles,
        Checkbox.styles,
        TimeInput.styles,
        css`
            :host {
                --dialog-max-width: 400px;
            }
        `,
    ];

    renderContent() {
        if (!this._availability) {
            return html``;
        }

        // const today = toDateString(new Date());
        const editing = !!this._availability.id;
        const date = this._availability.date;
        const status = this._status;

        return html`
            <form @submit=${this._submit} class="double-padded double-spacing vertical layout">
                <div class="margined horizontal start-aligning layout">
                    <div class="faded stretch">${editing ? "Verfügbarkeit Bearbeiten:" : "Neue Verfügbarkeit:"}</div>
                    <div ?hidden=${!editing}>
                        <button type="button" class="subtle transparent icon" @click=${this._delete}>
                            <i class="trash-alt"></i>
                        </button>
                    </div>
                </div>

                <div class="horizontal center-aligning layout">
                    <div
                        class="big half-padded colored-text stretch"
                        style="--color-highlight: ${availabilityColor(status)}"
                    >
                        <strong>${formatWeekDayShort(date)}</strong>, ${formatDate(date)}
                    </div>

                    <div class="padded box">
                        <i class="history"></i>
                        <select class="transparent negatively-margined" name="repeats">
                            <option .value=${Repeat.Never}>Einmalig</option>
                            <option .value=${Repeat.Weekly}>Wöchentlich</option>
                            <option .value=${Repeat.Monthly}>Monatlich</option>
                            <option .value=${Repeat.Yearly}>Jährlich</option>
                        </select>
                    </div>
                </div>

                <div class="spacing grid">
                    ${Object.values(AvailabilityStatus).map(
                        (s) => html`
                            <button
                                class="subtle"
                                type="button"
                                .value=${s}
                                style="--color-highlight: ${availabilityColor(s)}"
                                ?active=${status === s}
                                @click=${() => (this._status = s)}
                            >
                                <i class="${availabilityIcon(s)}"></i> ${availabilityLabel(s)}
                            </button>
                        `
                    )}
                </div>

                <ptc-checkbox-button
                    label="Ganztägig"
                    .buttonClass=${"ghost"}
                    .checked=${live(this._fullDay)}
                    @change=${this._fullDayToggled}
                ></ptc-checkbox-button>

                <ptc-drawer .collapsed=${this._fullDay}>
                    <div class="horizontal spacing stretching centering layout">
                        <ptc-time-input class="slim" name="start"></ptc-time-input>
                        <i class="dash">–</i>
                        <ptc-time-input class="slim" name="end"></ptc-time-input>
                    </div>
                </ptc-drawer>

                <div class="field">
                    <label><i class="comment"></i>Kommentar</label>
                    <textarea name="comment" placeholder="Optional"></textarea>
                </div>

                <div class="evenly stretching spacing horizontal layout">
                    <button class="primary">Speichern</button>
                    <button type="button" class="transparent" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
